import { Component, OnInit } from '@angular/core';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import {NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  providers: [NgbTabsetConfig]
})
export class AboutComponent implements OnInit {

  public company:boolean=true;

  constructor(config: NgbTabsetConfig) { 
    config.justify = 'center';
    config.type = 'pills';
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'management') {
      this.company=false;
    }else{
      this.company=true;
    }
  };

  ngOnInit() {
   
  }

}
