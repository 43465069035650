import { Component, OnInit } from '@angular/core';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  providers: [NgbTabsetConfig]
})
export class ProductsComponent implements OnInit {

  public concrete: boolean = true;
  public specifications: boolean = false;
  public paving: boolean = false;

  constructor(config: NgbTabsetConfig) {
    config.justify = 'center';
    config.type = 'pills';
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'paving') {
      this.concrete = false;
      this.specifications = false;
      this.paving = true;

    } else if ($event.nextId === 'specifications') {

      this.concrete = false;
      this.specifications = true;
      this.paving = false;
    }
    else {
      this.concrete = true;
      this.specifications = false;
      this.paving = false;
    }
  };

  ngOnInit() {

  }

}
